import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private toastrService: ToastrService
  ) { }

  successAlert(text = 'Operation Success') {
    this.toastrService.success(text);
  }

  failedAlert(text = 'Operation Failed !') {
    this.toastrService.error(text);
  }

  unauthorizedAlert(text = 'Unauthorized') {
    this.toastrService.error(text);
  }

  serverErrorAlert(text = 'Server Error') {
    this.toastrService.error(text);
  }

}
